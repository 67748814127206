// src/components/EpisodeDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Typography, CircularProgress, List, ListItem, ListItemText, Paper, Button } from '@mui/material';

function EpisodeDetails() {
  const { id } = useParams();
  const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';  // Dynamic API URL
  const [episode, setEpisode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [drinks, setDrinks] = useState([]);  // State to store drink details

  useEffect(() => {
    fetch(`${API_URL}/api/episodes/${id}/`, { credentials: 'include' })
      .then(response => response.json())
      .then(data => {
        setEpisode(data);
        setLoading(false);

        // Fetch each drink's details if episode contains drinks
        if (data.drinks.length > 0) {
          Promise.all(
            data.drinks.map(drinkId =>
              fetch(`${API_URL}/api/drinks/${drinkId}/`, { credentials: 'include' })  // Add credentials here
                .then(res => res.json())
                .catch(error => {
                  console.error(`Error fetching drink with id ${drinkId}:`, error);
                })
            )
          ).then(setDrinks);
        }
      })
      .catch(error => {
        console.error('Error fetching episode details:', error);
        setLoading(false);
      });
  }, [id, API_URL]);

  if (loading) {
    return (
      <Container>
        <CircularProgress />
        <Typography variant="h6" align="center">Loading episode details...</Typography>
      </Container>
    );
  }

  if (!episode) {
    return (
      <Container>
        <Typography variant="h6" align="center">Episode not found</Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h3" gutterBottom>{episode.name}</Typography>
        <Typography variant="body1"><strong>Air Date:</strong> {episode.episode_air_date}</Typography>
        <Typography variant="body1"><strong>Episode Number:</strong> {episode.episode_number || 'N/A'}</Typography>
        <Typography variant="body1">
          <strong>Watch Episode:</strong> 
          <a href={episode.episode_url} target="_blank" rel="noopener noreferrer"> Click Here</a>
        </Typography>
      </Paper>

      <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h5">Drinks in Episode</Typography>
        {drinks.length > 0 ? (
          <List>
            {drinks.map((drink, index) => (
              <ListItem key={index}>
                <ListItemText 
                  primary={
                    <Link to={`/drinks/${drink.id}`}>
                      {drink.name}
                    </Link>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1">No drinks in this episode</Typography>
        )}
      </Paper>

      <Button variant="contained" color="primary" component={Link} to="/episodes">
        Back to Episodes
      </Button>
    </Container>
  );
}

export default EpisodeDetails;
