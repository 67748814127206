import { createTheme } from '@mui/material/styles';

// Import the font files
import EvelethCleanRegularWoff2 from './assets/fonts/EvelethCleanRegular.woff2';
import EvelethCleanRegularWoff from './assets/fonts/EvelethCleanRegular.woff';

const evelethCleanRegular = {
  fontFamily: 'EvelethCleanRegular',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('EvelethCleanRegular'),
    url(${EvelethCleanRegularWoff2}) format('woff2'),
    url(${EvelethCleanRegularWoff}) format('woff')
  `,
  fontDisplay: 'swap',
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff4400',
    },
    secondary: {
      main: '#961900',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: 14,
    h1: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '2rem',
    },
    h2: {
      fontFamily: 'Helvetica',
      fontSize: '2rem',
    },
    h3: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '1.5rem',
    },
    h6: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '.9rem',
    },
    h5: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '.75rem',
    },
    h4: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '.3rem',
    },
    body1: {
      fontFamily: 'Helvetica',
      fontSize: '1rem', // General body text size
      color: '#ededed', // Color for filters
    },
    button: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '.25rem', // Font size for buttons like "View Drink"
      fontWeight: 'bold', // Bold text for buttons
    },
    subtitle1: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '.25rem', // Font size for dropdown items
    },
    input: {
      fontFamily: 'EvelethCleanRegular',
      fontSize: '.5rem', // Adjust this size for the search box text
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#ffffff',  // Custom link color #780014
          textDecoration: 'underline',
          fontFamily: 'EvelethCleanRegular',  // Custom font family for links
          fontWeight: 700,  // Bold text for links
          textShadow: '3px 3px 2px #511B24',  // Add drop shadow
          '&:hover': {
            color: '#BF0020',  // Hover state color
            textDecoration: 'underline',
            fontWeight: 900,  // Even bolder on hover
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '& a': {
            color: '#ffffff',  // Custom link color for typography-based links
            textDecoration: 'underline',
            fontFamily: 'EvelethCleanRegular',  // Custom font family for typography-based links
            fontWeight: 400,  // Regular weight for links inside typography
            textShadow: '3px 3px 2px #511B24',  // Add drop shadow to typography links
            '&:hover': {
              color: '#BF0020',  // Hover state for links
              textDecoration: 'underline',
              fontWeight: 700,  // Bold on hover for typography-based links
            },
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: '#ffffff',  // Custom text color for ingredients
          fontFamily: 'EvelethCleanRegular',  // Custom font family
          fontWeight: 400,  // Normal weight, adjust to your needs
        },
        primary: {
          fontWeight: 700,  // Bold font for the primary text
          fontSize: '1rem',  // Font size for the ingredient text
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: 'Helvetica',
          fontWeight: 'bold', // Bold text for labels
          fontSize: '1rem', // Checkbox label text
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'EvelethCleanRegular',
          fontSize: '.85rem', // Font size for buttons
          fontWeight: 'bold', // Bold font for buttons
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            fontFamily: 'Helvetica',
            fontWeight: 'bold', // Bold text for search input
            fontSize: '1rem', // Font size for search input text
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: 'EvelethCleanRegular',
          fontSize: '1rem', // Font size for dropdown text
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#bf0020', // Set a solid background color instead
          color: '#fff',  // Text color on the AppBar
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [evelethCleanRegular],  // Include the font globally
        body: {
          backgroundColor: '#2b2b2b', // Apply background color to the whole app
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#3a3a3a', // Dark background for Paper components
          color: '#ffffff', // Ensure text is readable
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#bf0020',  // Background color for selected chips
          color: '#ffffff',  // Text color
          fontWeight: 'bold',  // Make text bold
          fontFamily: 'EvelethCleanRegular',  // Use your desired font family
          margin: '5px',  // Add some margin around the chips
        },
        deleteIcon: {
          color: '#ffffff',  // Color of the "X" delete icon
          '&:hover': {
            color: '#ff0000',  // Change the color of delete icon on hover
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontFamily: 'EvelethCleanRegular',  // Use desired font
          fontSize: '0.85rem',  // Adjust font size
          backgroundColor: '#3a3a3a',  // Background color for the buttons
          color: '#ffffff',  // Text color for unselected buttons
          border: '1px solid #444',  // Border for the buttons
          '&.Mui-selected': {
            backgroundColor: '#ffffff',  // Background color for selected buttons #ffffff
            color: '#3a3a3a',  // Text color for selected buttons #3a3a3a
            fontWeight: 'bold',  // Bold text for selected buttons
          },
          '&:hover': {
            backgroundColor: '#666666',  // Hover background for unselected buttons
            color: '#fff',  // Hover text color for unselected buttons
          },
        },
      },
    },
    // Accordion Transition Speed Override
    MuiCollapse: {
      defaultProps: {
        timeout: 250, // Adjust the open/close speed here
      },
    },
  },
});

export default theme;
