import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  ToggleButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Button,
  CircularProgress,
  Box,
  Switch,
  FormControlLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

function Drinks() {
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';
  const [sortField, setSortField] = useState('name'); // Default to alphabetical
  const [sortOrder, setSortOrder] = useState('asc'); // Default to ascending
  const [drinks, setDrinks] = useState([]);
  const [loading, setLoading] = useState(true); // Initial page load
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedDescriptors, setSelectedDescriptors] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [isCursed, setIsCursed] = useState(false);
  const [showFavorites, setShowFavorites] = useState(false);
  const [selectedPreparationMethods, setSelectedPreparationMethods] = useState([]);
  const [availableDescriptors, setAvailableDescriptors] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [availableIngredients, setAvailableIngredients] = useState([]);
  const [availableMedia, setAvailableMedia] = useState([]);
  const [filterMode, setFilterMode] = useState('OR');

    // Function to toggle sort order
  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'alphabetical' ? 'abv_desc' : 'alphabetical'));
  };

  // State declarations at the top
  const [loadingFilters, setLoadingFilters] = useState({
    descriptors: false,
    tags: false,
    ingredients: false,
    media: false,
  });
  const [expandedAccordion, setExpandedAccordion] = useState('');
  const [dataLoaded, setDataLoaded] = useState({
    descriptors: false,
    tags: false,
    ingredients: false,
    media: false,
  });
  const [filtersLoaded, setFiltersLoaded] = useState(false);

  // Load filters from local storage on component mount
  useEffect(() => {
    const cachedState = sessionStorage.getItem('cachedDrinksPageState');
    if (cachedState) {
      const { filters, drinks } = JSON.parse(cachedState);
      setSearchTerm(filters.searchTerm || '');
      setSelectedDescriptors(filters.selectedDescriptors || []);
      setSelectedTags(filters.selectedTags || []);
      setSelectedIngredients(filters.selectedIngredients || []);
      setSelectedMedia(filters.selectedMedia || []);
      setIsCursed(filters.isCursed || false);
      setFilterMode(filters.filterMode || 'OR');
      setShowFavorites(filters.showFavorites || false);
      setSelectedPreparationMethods(filters.selectedPreparationMethods || []);
      setDrinks(drinks || []); // Set cached drinks data
      setLoading(false); // Skip loading if cache is available
      setFiltersLoaded(true);
    } else {
      const savedFilters = localStorage.getItem('drinkFilters');
      if (savedFilters) {
        const filters = JSON.parse(savedFilters);
        setSearchTerm(filters.searchTerm || '');
        setSelectedDescriptors(filters.selectedDescriptors || []);
        setSelectedTags(filters.selectedTags || []);
        setSelectedIngredients(filters.selectedIngredients || []);
        setSelectedMedia(filters.selectedMedia || []);
        setIsCursed(filters.isCursed || false);
        setFilterMode(filters.filterMode || 'OR');
        setShowFavorites(filters.showFavorites || false);
        setSelectedPreparationMethods(filters.selectedPreparationMethods || []);
      }
      setFiltersLoaded(true);
    }
  }, []);

  // Preload all filter data on component mount
  useEffect(() => {
    loadFilterData('tags');
    loadFilterData('ingredients');
    loadFilterData('media');
    loadFilterData('descriptors');
  }, []);

  // Load filter data if there are selected filters
  useEffect(() => {
    if (filtersLoaded) {
      if (selectedTags.length > 0 && !dataLoaded.tags) {
        loadFilterData('tags');
      }
      if (selectedIngredients.length > 0 && !dataLoaded.ingredients) {
        loadFilterData('ingredients');
      }
      if (selectedMedia.length > 0 && !dataLoaded.media) {
        loadFilterData('media');
      }
      if (selectedDescriptors.length > 0 && !dataLoaded.descriptors) {
        loadFilterData('descriptors');
      }
    }
  }, [filtersLoaded]);

  // Save filters to local storage whenever they change
  useEffect(() => {
    if (filtersLoaded) {
      const filtersToSave = {
        searchTerm,
        selectedDescriptors,
        selectedTags,
        selectedIngredients,
        selectedMedia,
        isCursed,
        filterMode,
        showFavorites,
        selectedPreparationMethods,
      };
      localStorage.setItem('drinkFilters', JSON.stringify(filtersToSave));
      sessionStorage.setItem('cachedDrinksPageState', JSON.stringify({ filters: filtersToSave, drinks }));
    }
  }, [
    filtersLoaded,
    searchTerm,
    selectedDescriptors,
    selectedTags,
    selectedIngredients,
    selectedMedia,
    isCursed,
    filterMode,
    showFavorites,
    selectedPreparationMethods,
    drinks, // Add drinks to dependency array
  ]);

  // Update debounced search term with a delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 275);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  // Fetch drinks whenever filters change and filters are loaded
  useEffect(() => {
    if (filtersLoaded) {
      fetchFilteredDrinks();
    }
  }, [
    filtersLoaded,
    debouncedSearchTerm,
    selectedDescriptors,
    selectedTags,
    selectedIngredients,
    selectedMedia,
    isCursed,
    filterMode,
    showFavorites,
    sortOrder,
    sortField,
    selectedPreparationMethods,
  ]);

  // Function to send the selected filters to the backend and fetch matching drinks
  const fetchFilteredDrinks = async () => {
    try {
      const trimmedSearchTerm = debouncedSearchTerm.trim();  // Trim the search term here

      const params = new URLSearchParams({
        filter_mode: filterMode,
        categories: selectedCategories.join(','),
        ingredients: selectedIngredients.join(','),
        tags: selectedTags.join(','),
        media: selectedMedia.join(','),
        descriptors: selectedDescriptors.join(','),
        preparation_methods: selectedPreparationMethods.join(','),
        cursed: isCursed ? 'true' : 'false',
        search: debouncedSearchTerm.trim(),
        show_favorites: showFavorites ? 'true' : 'false',
        sort_field: sortField,
        sort_order: sortOrder,
      }).toString();

      const response = await fetch(`${API_URL}/api/filter-drinks/?${params}`, {
        method: 'GET',
        credentials: 'include',
      });

      const data = await response.json();
      setDrinks(data.drinks);
    } catch (error) {
      console.error('Error fetching filtered drinks:', error);
    }
  };

  // Lazy load filter data and fetch detailed drinks if filters are opened
  const loadFilterData = async (filterType, forceLoad = false) => {
    if (loadingFilters[filterType] || dataLoaded[filterType]) return;

    setLoadingFilters((prevState) => ({ ...prevState, [filterType]: true }));
    let endpoint = '';
    switch (filterType) {
      case 'descriptors':
        endpoint = '/api/descriptor-options/';
        break;
      case 'tags':
        endpoint = '/api/tag-options/';
        break;
      case 'ingredients':
        endpoint = '/api/ingredient-options/';
        break;
      case 'media':
        endpoint = '/api/media-options/';
        break;
      default:
        return;
    }

    try {
      const response = await fetch(`${API_URL}${endpoint}`, {
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      // Sorting each filter alphabetically before setting it in the state
      if (filterType === 'ingredients') {
        const sortedIngredients = (data.ingredients || []).sort((a, b) => a.name.localeCompare(b.name));
        setAvailableIngredients(sortedIngredients);
      } else if (filterType === 'tags') {
        const sortedTags = (data.tags || []).sort((a, b) => a.name.localeCompare(b.name));
        setAvailableTags(sortedTags);
      } else if (filterType === 'media') {
        const sortedMedia = (data.media || []).sort((a, b) => a.title.localeCompare(b.title));
        setAvailableMedia(sortedMedia);
      } else if (filterType === 'descriptors') {
        const sortedDescriptors = (data.descriptors || []).sort((a, b) => a.name.localeCompare(b.name));
        setAvailableDescriptors(sortedDescriptors);
      }

      // Mark data as loaded
      setDataLoaded((prevState) => ({ ...prevState, [filterType]: true }));
    } catch (error) {
      console.error(`Error fetching ${filterType}:`, error);
    } finally {
      // Ensure loading state is updated regardless of success or error
      setLoadingFilters((prevState) => ({ ...prevState, [filterType]: false }));
    }
  };

  const handleToggleChange = (selectedArray, setSelectedArray, value) => {
    if (selectedArray.includes(value)) {
      setSelectedArray(selectedArray.filter((item) => item !== value));
    } else {
      setSelectedArray([...selectedArray, value]);
    }
  };


  const handleChipDelete = (setSelectedArray, value) => {
    setSelectedArray((prevSelectedArray) => prevSelectedArray.filter((item) => item !== value));
  };


  const handleClearAll = () => {
    setSearchTerm('');
    setSelectedCategories([]);
    setSelectedDescriptors([]);
    setSelectedTags([]);
    setSelectedIngredients([]);
    setSelectedMedia([]);
    setSelectedPreparationMethods([]);
    setIsCursed(false);
  };

  const filteredDrinks = drinks;

  return (
    <Container>
      <Box my={3}>
        <Typography variant="h3" align="center" gutterBottom>
          Drinks
        </Typography>
      </Box>

      {/* Search Input */}
      <TextField
        label="Search drinks..."
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        margin="normal"
      />

    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"       // Allow wrapping when necessary
      gap={1}               // Add spacing between switches
      mb={2}
    >
      {/* Filter Mode Toggle: AND/OR */}
      <FormControlLabel
        control={
          <Switch
            checked={filterMode === 'AND'}
            onChange={() => setFilterMode(filterMode === 'AND' ? 'OR' : 'AND')}
            color="primary"
            style={{ transform: 'scale(0.8)' }} // Scale switch size
          />
        }
        label={
          <Box display="flex" alignItems="center">
            <Typography
              variant="body2"
              style={{
                fontSize: '0.9rem', // Reduce font size slightly
                color: filterMode === 'AND' ? '#FFA500' : 'inherit',
                fontWeight: filterMode === 'AND' ? 'bold' : 'normal',
              }}
            >
              AND
            </Typography>
            <Typography variant="body2" style={{ margin: '0 5px', fontSize: '0.9rem' }}>/</Typography>
            <Typography
              variant="body2"
              style={{
                fontSize: '0.9rem',
                color: filterMode === 'OR' ? '#FFA500' : 'inherit',
                fontWeight: filterMode === 'OR' ? 'bold' : 'normal',
              }}
            >
              OR
            </Typography>
          </Box>
        }
      />

      {/* Sort Field Toggle: Abc/ABV */}
      <FormControlLabel
        control={
          <Switch
            checked={sortField === 'abv'}
            onChange={() => setSortField(sortField === 'name' ? 'abv' : 'name')}
            color="primary"
            style={{ transform: 'scale(0.8)' }} // Scale switch size
          />
        }
        label={
          <Box display="flex" alignItems="center">
            <Typography
              variant="body2"
              style={{
                fontSize: '0.9rem',
                color: sortField === 'name' ? '#FFA500' : 'inherit',
                fontWeight: sortField === 'name' ? 'bold' : 'normal',
              }}
            >
              Abc
            </Typography>
            <Typography variant="body2" style={{ margin: '0 5px', fontSize: '0.9rem' }}>/</Typography>
            <Typography
              variant="body2"
              style={{
                fontSize: '0.9rem',
                color: sortField === 'abv' ? '#FFA500' : 'inherit',
                fontWeight: sortField === 'abv' ? 'bold' : 'normal',
              }}
            >
              ABV
            </Typography>
          </Box>
        }
      />

      {/* Sort Order Toggle: Up/Down */}
      <FormControlLabel
        control={
          <Switch
            checked={sortOrder === 'desc'}
            onChange={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
            color="primary"
            style={{ transform: 'scale(0.8)' }} // Scale switch size
          />
        }
        label={
          <Box display="flex" alignItems="center">
            <ArrowUpwardIcon
              fontSize="small"
              style={{
                color: sortOrder === 'asc' ? '#FFA500' : 'inherit',
              }}
            />
            <Typography variant="body2" style={{ margin: '0 5px', fontSize: '0.9rem' }}>/</Typography>
            <ArrowDownwardIcon
              fontSize="small"
              style={{
                color: sortOrder === 'desc' ? '#FFA500' : 'inherit',
              }}
            />
          </Box>
        }
      />

      {/* Favorites Toggle: All/Faves */}
      <FormControlLabel
        control={
          <Switch
            checked={showFavorites}
            onChange={() => setShowFavorites(!showFavorites)}
            color="primary"
            style={{ transform: 'scale(0.8)' }} // Scale switch size
          />
        }
        label={
          <Box display="flex" alignItems="center">
            <Typography
              variant="body2"
              style={{
                fontSize: '0.9rem',
                color: !showFavorites ? '#FFA500' : 'inherit',
                fontWeight: !showFavorites ? 'bold' : 'normal',
              }}
            >
              All
            </Typography>
            <Typography variant="body2" style={{ margin: '0 5px', fontSize: '0.9rem' }}>/</Typography>
            <Typography
              variant="body2"
              style={{
                fontSize: '0.9rem',
                color: showFavorites ? '#FFA500' : 'inherit',
                fontWeight: showFavorites ? 'bold' : 'normal',
              }}
            >
              Faves
            </Typography>
          </Box>
        }
      />
    </Box>




      {/* Accordion Filters */}
      {/* Category Accordion (currently disabled) */}
      {false && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Category</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {['classic', 'modern_classic', 'avant_garde', 'tiki', '70s_style'].map((category) => (
              <ToggleButton
                key={category}
                selected={selectedCategories.includes(category)}
                onChange={() => handleToggleChange(selectedCategories, setSelectedCategories, category)}
                value={category}
                style={{ margin: '5px', minWidth: '100px' }}
              >
                {category}
              </ToggleButton>
            ))}
          </AccordionDetails>
        </Accordion>
      )}

      {/* Preparation Method Accordion */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Preparation Method</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {['shaken', 'stirred', 'built'].map((method) => (
            <ToggleButton
              key={method}
              selected={selectedPreparationMethods.includes(method)}
              onChange={() => handleToggleChange(selectedPreparationMethods, setSelectedPreparationMethods, method)}
              value={method}
              style={{ margin: '5px', minWidth: '100px' }}
            >
              {method}
            </ToggleButton>
          ))}
        </AccordionDetails>
      </Accordion>

      {/* Ingredients Accordion */}
      <Accordion
        expanded={expandedAccordion === 'ingredients'}
        onChange={(event, isExpanded) => {
          setExpandedAccordion(isExpanded ? 'ingredients' : '');
          if (isExpanded && !dataLoaded.ingredients) {
            loadFilterData('ingredients');
          }
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Ingredients</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loadingFilters.ingredients ? (
            <CircularProgress />
          ) : availableIngredients.length > 0 ? (
            ['Base Spirit', 'Liqueur', 'Modifier', 'Syrup', 'Juice', 'Fruit', 'Dairy', 'Vegetable', 'Other', 'Garnish'].map((category) => {
              const ingredientsInCategory = availableIngredients
                .filter((ingredient) => ingredient.ingredient_category === category)
                .sort((a, b) => a.name.localeCompare(b.name));

              if (ingredientsInCategory.length === 0) return null;

              // Render "Base Spirit" directly without an accordion
              if (category === 'Base Spirit') {
                return (
                  <Box key={category} mb={2}>
                    <Typography variant="h6" gutterBottom>
                      {category}
                    </Typography>
                    <Box display="flex" flexWrap="wrap">
                      {ingredientsInCategory.map((ingredient) => (
                        <ToggleButton
                          key={ingredient.id}
                          selected={selectedIngredients.includes(ingredient.id)}
                          onChange={() => handleToggleChange(selectedIngredients, setSelectedIngredients, ingredient.id)}
                          value={ingredient.id}
                          style={{ margin: '5px', minWidth: '100px' }}
                        >
                          {ingredient.name}
                        </ToggleButton>
                      ))}
                    </Box>
                  </Box>
                );
              }

              // Render other categories within an accordion
              return (
                <Accordion key={category}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{category}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box display="flex" flexWrap="wrap">
                      {ingredientsInCategory.map((ingredient) => (
                        <ToggleButton
                          key={ingredient.id}
                          selected={selectedIngredients.includes(ingredient.id)}
                          onChange={() => handleToggleChange(selectedIngredients, setSelectedIngredients, ingredient.id)}
                          value={ingredient.id}
                          style={{ margin: '5px', minWidth: '100px' }}
                        >
                          {ingredient.name}
                        </ToggleButton>
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : (
            <Typography>No ingredients available</Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Tags Accordion */}
      <Accordion
        expanded={expandedAccordion === 'tags'}
        onChange={(event, isExpanded) => {
          setExpandedAccordion(isExpanded ? 'tags' : '');
          if (isExpanded && !dataLoaded.tags) {
            loadFilterData('tags');
          }
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Tags</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loadingFilters.tags ? (
            <CircularProgress />
          ) : Array.isArray(availableTags) && availableTags.length > 0 ? (
            availableTags.map((tag) => (
              <ToggleButton
                key={tag.id}
                selected={selectedTags.includes(tag.id)}
                onChange={() => handleToggleChange(selectedTags, setSelectedTags, tag.id)}
                value={tag.id}
                style={{ margin: '5px', minWidth: '100px' }}
              >
                {tag.name}
              </ToggleButton>
            ))
          ) : (
            <Typography>No tags available</Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Media Adapted From Accordion */}
      <Accordion
        expanded={expandedAccordion === 'media'}
        onChange={(event, isExpanded) => {
          setExpandedAccordion(isExpanded ? 'media' : '');
          if (isExpanded && !dataLoaded.media) {
            loadFilterData('media');
          }
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Media Adapted From</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loadingFilters.media ? (
            <CircularProgress />
          ) : Array.isArray(availableMedia) && availableMedia.length > 0 ? (
            availableMedia.map((media) => (
              <ToggleButton
                key={media.id}
                selected={selectedMedia.includes(media.id)}
                onChange={() => handleToggleChange(selectedMedia, setSelectedMedia, media.id)}
                value={media.id}
                style={{ margin: '5px', minWidth: '100px' }}
              >
                {media.title}
              </ToggleButton>
            ))
          ) : (
            <Typography>No media available</Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Selected Filters Chips */}
      <Box mt={2} mb={2}>
        {selectedCategories.map((category) => (
          <Chip key={category} label={category} onDelete={() => handleChipDelete(setSelectedCategories, category)} />
        ))}

        {selectedTags.map((tagId) => {
          const tag = availableTags.find((t) => t.id === tagId);
          return tag ? (
            <Chip key={tag.id} label={tag.name} onDelete={() => handleChipDelete(setSelectedTags, tagId)} />
          ) : null;
        })}

        {selectedIngredients.map((ingredientId) => {
          const ingredient = availableIngredients.find((i) => i.id === ingredientId);
          return ingredient ? (
            <Chip
              key={ingredient.id}
              label={ingredient.name}
              onDelete={() => handleChipDelete(setSelectedIngredients, ingredientId)}
            />
          ) : null;
        })}

        {selectedMedia.map((mediaId) => {
          const mediaItem = availableMedia.find((m) => m.id === mediaId);
          return mediaItem ? (
            <Chip key={mediaItem.id} label={mediaItem.title} onDelete={() => handleChipDelete(setSelectedMedia, mediaId)} />
          ) : null;
        })}

        {selectedPreparationMethods.map((method) => (
          <Chip key={method} label={method} onDelete={() => handleChipDelete(setSelectedPreparationMethods, method)} />
        ))}

        <Button onClick={handleClearAll}>Clear All</Button>
      </Box>

      {/* Drinks Grid */}
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        {filteredDrinks.length > 0 ? (
          filteredDrinks.map((drink) => (
            <Grid item xs={6} sm={3} md={2} key={drink.id}>
              <Link to={`/drinks/${drink.id}`} style={{ textDecoration: 'none' }}>
                <Card
                  style={{
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    transition: 'box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out',
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'scale(1.05)';
                    e.currentTarget.style.boxShadow = '0px 8px 12px rgba(0, 0, 0, 0.2)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'scale(1)';
                    e.currentTarget.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)';
                  }}
                >
                  <CardContent style={{ textAlign: 'center', position: 'relative' }}>
                    <Typography variant="h5" align="center">
                      {drink.name}
                    </Typography>
                    {drink.cursed && (
                      <SentimentVeryDissatisfiedIcon
                        style={{
                          color: 'green',
                          position: 'absolute',  // Position it absolutely within the card
                          top: '2px',          // Adjust this to move it outside or closer to the top corner
                          left: '2px',         // Adjust this for consistent alignment with the left edge
                          fontSize: '.9rem',
                        }}
                        titleAccess="Cursed drink"
                      />
                    )}
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6">No drinks found</Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default Drinks;
