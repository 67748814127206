// src/components/DrinkDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  Chip,
  Box,
  IconButton
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

function DrinkDetails() {
  const { id } = useParams();
  const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';  // Set dynamic API URL
  const [drink, setDrink] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFavorite, setIsFavorite] = useState(false);
  const [syrupDetails, setSyrupDetails] = useState([]); // State to store detailed syrup information
  const navigate = useNavigate();


  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '='))
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
      }
    }
    return cookieValue;
  }

  useEffect(() => {
    fetch(`${API_URL}/api/drinks/${id}/`, { credentials: 'include' })
      .then(response => response.json())
      .then(data => {
        // Make sure data.is_favorite is handled properly
        if (data.is_favorite !== undefined) {
          setIsFavorite(data.is_favorite);  // Set the favorite state based on the response
        } else {
          console.warn('Favorite status not found in API response.');
        }

        // Apply the sorting to the ingredients and handle syrups if applicable
        const order = ['Base Spirit', 'Modifier', 'Syrup', 'Juice', 'Other', 'Garnish'];
        const sortedIngredients = data.ingredients.sort((a, b) => {
          const aCategory = a.ingredient_category ? a.ingredient_category : 'Other';
          const bCategory = b.ingredient_category ? b.ingredient_category : 'Other';
          return order.indexOf(aCategory) - order.indexOf(bCategory);
        });

        // Set drink state after processing ingredients
        setDrink({ ...data, ingredients: sortedIngredients });

        // Handle syrup details if applicable (unchanged)
        if (data.syrups && data.syrups.length > 0) {
          const syrupFetches = data.syrups.map(syrup => {
            if (syrup && syrup.id) {
              return fetch(`${API_URL}/api/syrupdetail/${syrup.id}/`, { credentials: 'include' })
                .then(response => response.json());
            } else {
              console.error('Invalid syrup object:', syrup);
              return Promise.resolve(null);
            }
          });
          Promise.all(syrupFetches)
            .then((syrupsData) => {
              setSyrupDetails(syrupsData);
            })
            .catch(error => console.error('Error fetching syrup details:', error));
        }

        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching drink details:', error);
        setLoading(false);
      });
  }, [id, API_URL]);

  const toggleFavorite = () => {
    const url = `${API_URL}/api/drinks/${id}/${isFavorite ? 'unfavorite' : 'favorite'}/`;
    const method = isFavorite ? 'DELETE' : 'POST';
    const csrftoken = getCookie('csrftoken');

    fetch(url, {
      method: method,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
    })
      .then(response => {
        if (response.ok) {
          setIsFavorite(!isFavorite);
        } else {
          console.error('Failed to toggle favorite status:', response.statusText);
        }
      })
      .catch(error => {
        console.error('Error updating favorite status:', error);
      });
  };


  if (loading) {
    return (
      <Container>
        <CircularProgress />
        <Typography variant="h6" align="center">Loading drink details...</Typography>
      </Container>
    );
  }

  if (!drink) {
    return (
      <Container>
        <Typography variant="h6" align="center">Drink not found</Typography>
      </Container>
    );
  }

  return (
    <Container>
      {/* Drink Details */}
      <Paper elevation={3} sx={{ p: 3, mb: 3, position: 'relative' }}>
        {/* Display Cursed Icon in the top-left corner if the drink is cursed */}
        {drink.cursed && (
          <SentimentVeryDissatisfiedIcon
            style={{
              color: 'green',
              position: 'absolute',  // Position it absolutely within the Paper
              top: '8px',            // Adjust this to place it closer to the Paper's top
              left: '8px',           // Adjust for consistent left alignment
              fontSize: '1.2rem',    // Smaller font size for a more subtle look
            }}
            titleAccess="Cursed drink"
          />
        )}

        {/* Drink Title */}
        <Typography variant="h3" gutterBottom>{drink.name}</Typography>

        {/* Favorite/Unfavorite Button */}
        <Button
          variant="contained"
          color={isFavorite ? "secondary" : "primary"}
          onClick={toggleFavorite}
        >
          {isFavorite ? "Remove from KEEPER!" : "Add to KEEPER!"}
        </Button>

        {/* Show a warning if the drink needs review */}
        {drink.needs_review && (
          <Typography variant="h6" color="error" gutterBottom>
            Recipe details in progress
          </Typography>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Type:</strong> {drink.type_of_drink}</Typography>
            <Typography variant="body1"><strong>Category:</strong> {drink.category}</Typography>
            <Typography variant="body1"><strong>Descriptor:</strong> {drink.descriptor}</Typography>
            <Typography variant="body1"><strong>Preparation Method:</strong> {drink.preparation_method}</Typography>
            <Typography variant="body1"><strong>Garnish:</strong> {drink.garnish ? drink.garnish.name : 'None'}</Typography>
            <Typography variant="body1"><strong>ABV:</strong> {drink.abv}%</Typography>
            <Typography variant="body1"><strong>Notes:</strong> {drink.notes || 'None'}</Typography>
            <Typography variant="body1"><strong>Process:</strong> {drink.process || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Ingredients</Typography>
            <List>
              {drink.ingredients.map((ingredient, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={`${Number(ingredient.quantity).toFixed(2).replace(/\.00$/, '').replace(/(\.\d)0$/, '$1')} ${ingredient.unit_fk ? ingredient.unit_fk.name : ''} ${ingredient.unit_fk ? 'of' : ''} ${ingredient.ingredient_attribute ? ingredient.ingredient_attribute.attribute_type : ingredient.ingredient.name}`}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Paper>

      {/* Syrup Recipes */}
      {syrupDetails.length > 0 && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5">Associated Syrup Recipes</Typography>
          {syrupDetails.map((syrup, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Typography variant="h6">{syrup.name}</Typography>
              <Typography variant="body1"><strong>Type:</strong> {syrup.type_of_syrup}</Typography>
              <Typography variant="body1"><strong>Process:</strong> {syrup.process || 'N/A'}</Typography>
              <Typography variant="body1"><strong>Notes:</strong> {syrup.notes || 'None'}</Typography>
              <Typography variant="h6">Ingredients</Typography>
              <List>
                {syrup.ingredients.map((ingredient, index) => (
                  <ListItem key={index}>
                    <ListItemText
                      primary={`${Number(ingredient.quantity).toFixed(2).replace(/\.00$/, '').replace(/(\.\d)0$/, '$1')} ${ingredient.unit_fk ? ingredient.unit_fk.name : ''} ${ingredient.unit_fk ? 'of' : ''} ${ingredient.ingredient.name}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Paper>
      )}

      {/* Adapted From Media */}
      {drink.media_adapted_from.length > 0 && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5">Adapted From Media</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {drink.media_adapted_from.map((media, index) => (
              <Chip key={index} label={media.title} variant="outlined" />
            ))}
          </Box>
        </Paper>
      )}

      {/* Tags */}
      {drink.tags.length > 0 && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5">Tags</Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {drink.tags.map((tag, index) => (
              <Chip key={index} label={tag.name} variant="outlined" />
            ))}
          </Box>
        </Paper>
      )}

      {/* Episodes */}
      {drink.episodes.length > 0 && (
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5">Episodes</Typography>
          <List>
            {drink.episodes.map((episode, index) => (
              <ListItem key={index}>
                <ListItemText 
                  primary={
                    <Link to={`/episodes/${episode.id}`}>
                      {episode.name} (Aired on {episode.episode_air_date})
                    </Link>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      {/* Back to Drinks link */}
      <Box mt={2} mb={4}>
        <Button
          onClick={() => navigate(-1)}
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginBottom: '16px' }}
        >
          Back to Drinks
        </Button>
      </Box>
    </Container>
  );
}

export default DrinkDetails;
