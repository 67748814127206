import React, { useState, useEffect } from 'react';
import { Container, Grid, Button, Typography, Grow, Box } from '@mui/material';
import axios from 'axios';

// Use the API URL from environment variables
const apiUrl = process.env.REACT_APP_API_URL;

function HomePage() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login state
  const [hasAccess, setHasAccess] = useState(false); // Track access level based on backend response
  const [loading, setLoading] = useState(true); // Track loading state

  // Check login and access status on page load
  useEffect(() => {
    axios
      .get(`${apiUrl}/api/auth/status/`, { withCredentials: true })
      .then(response => {
        if (response.data.isAuthenticated) {
          setIsLoggedIn(true);
          localStorage.setItem('isAuthenticated', 'true');

          axios
            .get(`${apiUrl}/api/check_patreon_membership`, { withCredentials: true })
            .then(patreonResponse => {
              if (patreonResponse.data.hasAccess) {
                setHasAccess(true);
              } else {
                setHasAccess(false);
              }
              setLoading(false);
            })
            .catch(error => {
              console.error('Error checking access level:', error);
              setLoading(false);
            });
        } else {
          setIsLoggedIn(false);
          setHasAccess(false);
          localStorage.removeItem('isAuthenticated');
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching login status:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Loading state while checking login and access level
  }

  return (
    <>
      <Grow in timeout={1000}>
        <Container>
          <Grid container justifyContent="center" alignItems="center" spacing={2} style={{ height: '100vh' }}>
            <Grid item xs={12}>
              <Typography variant="h1" align="center">
                Welcome to How to Drink!
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Button
                component="a"
                href="/drinks"
                variant="contained"
                color="secondary"
                fullWidth
                sx={{
                  padding: '15px 20px',             // Padding inside the button
                  fontSize: '2rem',               // Font size for the button text
                  fontWeight: 'bold',               // Make the text bold
                  borderRadius: '8px',              // Rounded corners
                  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',  // Box shadow for depth
                  transition: 'all 0.3s ease-in-out', // Smooth transition for hover effects
                  '&:hover': {
                    backgroundColor: '#BF0020',    // Change color on hover
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.5)', // Hover shadow
                    transform: 'translateY(-2px)', // Slight lift on hover
                  },
                }}
              >
                Drinks
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                component="a"
                href="/episodes"
                variant="contained"
                color="secondary"
                fullWidth
                sx={{
                  padding: '15px 20px',
                  fontSize: '2rem',
                  fontWeight: 'bold',
                  borderRadius: '8px',
                  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    backgroundColor: '#BF0020',    // Change secondary color on hover
                    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.5)',
                    transform: 'translateY(-2px)',
                  },
                }}
              >
                Episodes
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grow>

      {/* Blurry background with login prompt if not logged in */}
      {!isLoggedIn && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              padding: '2rem',
              backgroundColor: '#404040',
              borderRadius: '16px',
              boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h5" gutterBottom>
              Please login to continue
            </Typography>
            <Button href={`${apiUrl}/auth/login/patreon/`} variant="contained" color="success">
              Login with Patreon
            </Button>
          </Box>
        </Box>
      )}

      {/* Check access level */}
      {isLoggedIn && !hasAccess && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backdropFilter: 'blur(10px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              padding: '2rem',
              backgroundColor: '#404040',
              borderRadius: '16px',
              boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h5" gutterBottom>
              You need to be an active patron or have PremiumUser access to view this content.
            </Typography>
            <Button href="https://www.patreon.com/" variant="contained" color="success">
              Become a Patron
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}

export default HomePage;
