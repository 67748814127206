import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  // Function to fetch CSRF token before making a login request
  const fetchCsrfToken = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/get-csrf-token/`, {
        withCredentials: true,
      });
      setCsrfToken(response.data.csrfToken);
    } catch (error) {
      console.error('Error fetching CSRF token:', error);
    }
  };

  useEffect(() => {
    fetchCsrfToken();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `${apiUrl}/api/login/`,
        new URLSearchParams({ username, password }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CSRFToken': csrfToken,
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        // After successful login, check the user's access level
        await checkUserAccess();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setError('Invalid username or password');
        } else if (error.response.status === 403) {
          setError('CSRF token missing or incorrect');
        } else if (error.response.status === 400) {
          setError('Login failed. Please check your credentials.');
        } else {
          setError('Login failed. Please try again.');
        }
      } else {
        setError('Login failed. Please try again.');
      }
    }
  };

  // Function to check user access after login
  const checkUserAccess = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/check_patreon_membership/`, {
        withCredentials: true,
      });

      const { hasAccess, isPatron, message } = response.data;

      console.log('Access check response:', response.data); // Debugging statement

      if (hasAccess) {
        // User has access, proceed to the main app
        navigate('/'); // Ensure this route matches your main app route
      } else {
        // User does not have access
        setError('You do not have access to this app.');
        // Optionally, navigate to an upgrade or information page
        // navigate('/upgrade');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // User is not authenticated
        setError('Authentication required. Please log in.');
      } else {
        setError('Error checking access. Please try again later.');
      }
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required // Add required attribute
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required // Add required attribute
          />
        </div>
        <button type="submit">Login</button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div>
        <p>Or log in with Patreon:</p>
        <a href={`${apiUrl}/auth/login/patreon/`}>Log in with Patreon</a>
      </div>
    </div>
  );
}

export default LoginPage;
